import { gql } from '@apollo/client';

export const ASSIGNMENT_FRAG = gql`
  fragment AssignmentFrag on Assignment {
    id
    type
    level
    makeUp
    assignedDate
    dueDate
    assignWay
    numberOfStyle
    product {
      id
      name
    }
    title {
      id
      title
      _product
    }
    bookset {
      id
      title
      level
    }
    book {
      id
      title
    }
    unit {
      id
      title
    }
    section {
      id
      title
      type
    }
    activitys {
      id
      title
    }
    assigner {
      id
      name
      username
    }
    schools {
      id
      name
    }
    classrooms {
      id
      name
      isDeleted
    }
    students {
      id
      name
      username
      classroom {
        id
        name
      }
    }
  }
`;
